var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-sider',{class:( _obj = {}, _obj[_vm.$style.menu] = true, _obj[_vm.$style.white] = _vm.settings.menuColor === 'white', _obj[_vm.$style.gray] = _vm.settings.menuColor === 'gray', _obj[_vm.$style.dark] = _vm.settings.menuColor === 'dark', _obj[_vm.$style.unfixed] = _vm.settings.isMenuUnfixed, _obj[_vm.$style.shadow] = _vm.settings.isMenuShadow, _obj ),attrs:{"width":_vm.settings.leftMenuWidth,"collapsible":_vm.settings.isMobileView ? false : true,"collapsed":_vm.settings.isMenuCollapsed && !_vm.settings.isMobileView},on:{"collapse":_vm.onCollapse}},[_c('div',{class:_vm.$style.menuOuter,style:({
      width:
        _vm.settings.isMenuCollapsed && !_vm.settings.isMobileView
          ? '80px'
          : _vm.settings.leftMenuWidth + 'px',
      height:
        _vm.settings.isMobileView || _vm.settings.isMenuUnfixed
          ? 'calc(100% - 64px)'
          : 'calc(100% - 110px)',
    })},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"45px"},attrs:{"src":_vm.getBuilding.logo,"alt":_vm.getBuilding.name}}),_c('div',{class:_vm.$style.descr},[_c('div',{class:_vm.$style.name},[_vm._v(" "+_vm._s(_vm.getBuilding.name)+" ")])])])]),_c('perfect-scrollbar',{staticClass:"scroll-area",style:({
        height:
          _vm.settings.isMobileView || _vm.settings.isMenuUnfixed
            ? 'calc(100vh - 64px)'
            : 'calc(100vh - 110px)',
      })},[_c('a-menu',{class:_vm.$style.navigation,style:({
          height:
            _vm.settings.isMobileView || _vm.settings.isMenuUnfixed
              ? 'calc(100vh - 64px)'
              : 'calc(100vh - 110px)',
        }),attrs:{"inlineCollapsed":_vm.settings.isMobileView ? false : _vm.settings.isMenuCollapsed,"open-keys":_vm.openKeys,"mode":'inline',"selectedKeys":_vm.selectedKeys,"inlineIndent":15},on:{"click":_vm.handleClick,"openChange":_vm.handleOpenChange},model:{value:(_vm.currentMenuItem),callback:function ($$v) {_vm.currentMenuItem=$$v},expression:"currentMenuItem"}},[_vm._l((_vm.menuData),function(item,index){return [(_vm.verifyMenuItemAccess(item))?[(item.category)?_c('a-menu-item-group',{key:index},[_c('template',{slot:"title"},[(item.version == 'v1')?_c('a-icon',{staticClass:"mr-2",staticStyle:{"color":"#f5222d"},attrs:{"type":"clock-circle"}}):_vm._e(),_vm._v(_vm._s(_vm.$t(item.title)))],1)],2):_vm._e(),(!item.children && !item.category)?_c('item',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e(),(item.children)?_c('sub-menu',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e()]:_vm._e()]})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }