<template>
  <a-layout-sider
    :width="settings.leftMenuWidth"
    :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView"
    @collapse="onCollapse"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width:
          settings.isMenuCollapsed && !settings.isMobileView
            ? '80px'
            : settings.leftMenuWidth + 'px',
        height:
          settings.isMobileView || settings.isMenuUnfixed
            ? 'calc(100% - 64px)'
            : 'calc(100% - 110px)',
      }"
    >
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <img
            :src="getBuilding.logo"
            class="mr-2"
            :alt="getBuilding.name"
            style="width: 45px"
          />
          <div :class="$style.descr">
            <div :class="$style.name">
              {{ getBuilding.name }}
            </div>
          </div>
        </div>
      </div>
      <perfect-scrollbar
        :style="{
          height:
            settings.isMobileView || settings.isMenuUnfixed
              ? 'calc(100vh - 64px)'
              : 'calc(100vh - 110px)',
        }"
        class="scroll-area"
      >
        <a-menu
          :inlineCollapsed="
            settings.isMobileView ? false : settings.isMenuCollapsed
          "
          v-model="currentMenuItem"
          :open-keys="openKeys"
          :mode="'inline'"
          :selectedKeys="selectedKeys"
          @click="handleClick"
          @openChange="handleOpenChange"
          :inlineIndent="15"
          :class="$style.navigation"
          :style="{
            height:
              settings.isMobileView || settings.isMenuUnfixed
                ? 'calc(100vh - 64px)'
                : 'calc(100vh - 110px)',
          }"
        >
          <template v-for="(item, index) in menuData">
            <template v-if="verifyMenuItemAccess(item)">
              <a-menu-item-group :key="index" v-if="item.category">
                <template slot="title"
                  ><a-icon
                    v-if="item.version == 'v1'"
                    type="clock-circle"
                    style="color: #f5222d"
                    class="mr-2"
                  />{{ $t(item.title) }}</template
                >
              </a-menu-item-group>
              <item
                v-if="!item.children && !item.category"
                :menu-info="item"
                :styles="$style"
                :key="item.key"
              />
              <sub-menu
                v-if="item.children"
                :menu-info="item"
                :styles="$style"
                :key="item.key"
              />
            </template>
          </template>
        </a-menu>
      </perfect-scrollbar>
    </div>
  </a-layout-sider>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import store from "store";
import find from "lodash/find";
import { getMenuData } from "@/services/menu";
import SubMenu from "./partials/submenu";
import Item from "./partials/item";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "menu-left",
  components: { SubMenu, Item, PerfectScrollbar },
  computed: {
    ...mapState(["settings", "user"]),
    ...mapGetters("user", ["user"]),
    getBuilding() {
      return (
        this.settings.image.filter(
          (img) => img.db == this.settings.activeBuilding
        )[0] || {}
      );
    },
    getSchoolarYear() {
      let { start, end } = this.settings.schoolarYears.find(
        (el) => el._id == this.settings.activeSchoolarYear
      );
      return { start, end };
    },
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },
  watch: {
    "$store.state.settings.image": function () {
      console.log(this.$store.settings.image);
    },
  },
  mounted() {
    this.openKeys = [];
    this.selectedKeys = [];
    this.setSelectedKeys();
    this.currentMenuItemHandler();

    /* this.menuData = this.menuData.filter((item) => {
      const existing = this.user.access;
      if (existing.all) return item;
      const required = item.access;
      if (existing[required]) return item;
    });
    console.log(this.menuData);
    this.$router.push(this.menuData[0].url) */
  },
  created() {},
  data() {
    return {
      currentMenuItem: [],
      currentSubItem: [],
      building: {},
      menuData: getMenuData,
      selectedKeys: [],
      openKeys: [],
      rootSubmenuKeys: [],
    };
  },
  watch: {
    "settings.isMenuCollapsed"() {
      this.openKeys = [];
    },

    $route() {
      this.setSelectedKeys();
    },
  },
  methods: {
    currentMenuItemHandler() {
      this.currentMenuItem = store.get("app.menu.selectedKeys");
      this.openKeys = store.get("app.menu.openedKeys");
      if (!this.openKeys) this.openKeys = [];
    },
    verifyMenuItemAccess(item) {
      return (
        this.verifyBuildingAccess(item) &&
        this.verifyBulletinVersion(item) &&
        this.verifyRole(item)
      );
    },
    verifyBulletinVersion(item) {
      if (!item.version) return true;
      const currentSYEnd = new Date(this.getSchoolarYear.end);
      const version1End = new Date("2024-08-31T23:59:59.999Z"); // End of 31st August 2024
      const version = currentSYEnd < version1End ? "v1" : "v2";
      return item.version == version;
    },
    verifyRole: function (item) {
      const existing = this.user.access;
      if (existing.all) return true;
      const required = item.access;
      //   console.info(item.key, required);
      if (
        required.some(
          (val) =>
            existing[val] === true ||
            existing[item.accessGroup] === true || // for old bulletin access (bulletin was a boolean not an array)
            (existing[item.accessGroup] &&
              existing[item.accessGroup].length > 0 &&
              existing[item.accessGroup].includes(val))
        )
      )
        return true;
      else return false;
    },

    onCollapse: function (collapsed, type) {
      const value = !this.settings.isMenuCollapsed;
      this.$store.commit("settings/CHANGE_SETTING", {
        setting: "isMenuCollapsed",
        value,
      });
    },

    handleClick(e) {
      if (e.key === "settings") {
        this.$store.commit("settings/CHANGE_SETTING", {
          setting: "isSettingsOpen",
          value: true,
        });
        return;
      }
      store.set("app.menu.selectedKeys", [e.key]);
      this.selectedKeys = [e.key];
      e.keyPath.length <= 1 ? (this.openKeys = []) : null;
    },
    handleOpenChange(openKeys) {
      this.rootSubmenuKeys = getMenuData.map((e) => e.key);
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );

      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
      store.set("app.menu.openedKeys", this.openKeys);
    },
    setSelectedKeys() {
      const pathname = this.$route.path;
      const menuData = this.menuData.concat();
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item);
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key));
          }
          return flattenedItems;
        }, []);
      const selectedItem = find(flattenItems(menuData, "children"), [
        "url",
        pathname,
      ]);
      this.selectedKeys = selectedItem ? [selectedItem.key] : [];
    },
    verifyBuildingAccess: function (item) {
      if (!item.buildingAccess) return true;
      else {
        const buildingAccess = item.buildingAccess;
        if (this.schoolDetails.access.edManager[buildingAccess]) return true;
        else return false;
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
